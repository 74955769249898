<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5">
      <bullet :canBack="true" colorIcon="#052633" title="Rincian Tugas" />
      <v-card class="mt-3" flat color="#052633" rounded="lg">
        <v-row no-gutters>
          <v-col cols="12" md="8" style="display: grid">
            <div
              class="full_w pa-5 d-flex align-start justify-center flex-column"
            >
              <h2 v-if="!loading && dataSet" class="txt_white text-capitalize">
                {{ `${dataSet.event.tipe} - ${dataSet.event.klien.nama}` }}
              </h2>
              <v-skeleton-loader
                v-if="loading"
                class="rounded-lg"
                width="100px"
                height="20px"
                type="image"
              ></v-skeleton-loader>
              <v-skeleton-loader
                v-if="loading"
                class="rounded-lg mt-1"
                width="400px"
                height="20px"
                type="image"
              ></v-skeleton-loader>
              <div v-if="!loading && dataSet" class="mt-1 d-flex align-center">
                <v-icon color="white" class="mr-2"
                  >mdi-calendar-check-outline</v-icon
                >
                <h4 class="not_bold txt_white text-capitalize">
                  {{ dataSet.step ? dataSet.step.step : "-" }}
                </h4>
              </div>
              <v-skeleton-loader
                v-if="loading"
                class="rounded-lg mt-1"
                width="400px"
                height="20px"
                type="image"
              ></v-skeleton-loader>
              <div class="mt-2 d-flex align-center">
                <v-card
                  v-if="!loading && dataSet"
                  class="pa-2 mr-2 text-capitalize"
                  flat
                  dark
                  :color="
                    dataSet.status == 'done'
                      ? '#26AF65'
                      : dataSet.status == 'ongoing'
                      ? '#EDD57B'
                      : '#DC3545'
                  "
                >
                  {{ dataSet.status }}
                </v-card>
                <v-skeleton-loader
                  v-if="loading"
                  class="rounded-lg mr-2"
                  width="100px"
                  height="40px"
                  type="image"
                ></v-skeleton-loader>
                <v-card
                  v-if="!loading && dataSet"
                  class="pa-2 text-capitalize"
                  flat
                  dark
                  :color="
                    dataSet.prioritas == 'low'
                      ? '#408785'
                      : dataSet.prioritas == 'high'
                      ? '#633B18'
                      : '#BD9028'
                  "
                >
                  {{ dataSet.prioritas }}
                </v-card>
                <v-skeleton-loader
                  v-if="loading"
                  class="rounded-lg"
                  width="100px"
                  height="40px"
                  type="image"
                ></v-skeleton-loader>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="px-4 py-2 d-flex align-center justify-end"
          >
            <img
              src="../../assets/icon/icon Login.png"
              height="100px"
              style="object-fit: contain"
            />
          </v-col>
        </v-row>
      </v-card>
      <div class="mt-5 mb-2 px-2">
        <h3>Yang Bertugas</h3>
        <v-row no-gutters class="mt-2">
          <v-col cols="12" md="6" class="pa-2">
            <div v-if="dataSet && !loading" class="mt-2 d-flex align-center">
              <v-icon color="#052633" class="mr-2"
                >mdi-account-box-outline</v-icon
              >
              <h4 class="text-capitalize txt_color_default">
                {{ dataSet.karyawan.nama ? dataSet.karyawan.nama : "-" }}
              </h4>
            </div>
            <v-skeleton-loader
              v-if="loading"
              class="rounded-lg mt-2"
              width="100%"
              height="40px"
              type="image"
            ></v-skeleton-loader>
            <div v-if="dataSet && !loading" class="mt-2 d-flex align-center">
              <v-icon color="#052633" class="mr-2"
                >mdi-account-settings-outline
              </v-icon>
              <h4 class="text-capitalize txt_color_default">
                {{
                  dataSet.karyawan.status_karyawan
                    ? dataSet.karyawan.status_karyawan
                    : "-"
                }}
              </h4>
            </div>
            <v-skeleton-loader
              v-if="loading"
              class="rounded-lg mt-2"
              width="100%"
              height="40px"
              type="image"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6" class="pa-2">
            <div v-if="dataSet && !loading" class="mt-2 d-flex align-center">
              <v-icon color="#052633" class="mr-2"
                >mdi-briefcase-outline</v-icon
              >
              <h4 class="text-capitalize txt_color_default">
                {{ dataSet.karyawan.jabatan ? dataSet.karyawan.jabatan : "-" }}
              </h4>
            </div>
            <v-skeleton-loader
              v-if="loading"
              class="rounded-lg mt-2"
              width="100%"
              height="40px"
              type="image"
            ></v-skeleton-loader>
            <div v-if="dataSet && !loading" class="mt-2 d-flex align-center">
              <div class="rounded-lg d-flex align-center">
                <div class="rounded-lg d-flex align-center justify-center">
                  <v-icon color="#052633" class="mr-2">mdi-file-outline</v-icon>
                </div>
                <div
                  @click="dataSet.file ? toFile(dataSet.file) : null"
                  :style="`text-decoration-line:${
                    dataSet.file ? 'underline' : ''
                  } ; cursor:${dataSet.file ? 'pointer' : 'text'} `"
                >
                  {{ dataSet.file ? dataSet.file : "Tidak ada file" }}
                </div>
              </div>
            </div>
            <v-skeleton-loader
              v-if="loading"
              class="rounded-lg mt-2"
              width="100%"
              height="40px"
              type="image"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </div>
      <div class="mt-5 d-flex align-center justify-center">
        <div class="_temp_list_medium">
          <div v-if="!loading && dataSet">
            <v-row v-if="!dataSet.detailTask" no-gutters>
              <v-col cols="12" md="12" class="pa-1">
                <h4 class="txt_color_default">Tujuan Tugas</h4>
                <v-text-field
                  placeholder="Silakan isi tujuan tugas di sini"
                  v-model="field.goal"
                  color="#0D47A1"
                  outlined
                  dense
                  hide-details
                  class="rounded-lg mb-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pa-1">
                <h4 class="txt_color_default">Tenggat Waktu</h4>
                <v-menu
                  v-model="menuTenggat"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="field.tenggat"
                      prepend-inner-icon="mdi-calendar-range"
                      readonly
                      placeholder="Silakan isi tenggat waktu di sini"
                      hide-details
                      dense
                      v-on="on"
                      outlined
                      class="rounded-lg mb-2"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="field.tenggat"
                    @input="closeDateMenu(field.tenggat)"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" class="pa-1">
                <h4 class="txt_color_default">Metric</h4>
                <v-select
                  placeholder="Silakan pilih metric di sini"
                  outlined
                  hide-details
                  :items="optMetric"
                  item-text="text"
                  item-value="val"
                  dense
                  v-model="field.metric"
                  class="rounded-lg mb-2"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" class="pa-1">
                <h4 class="txt_color_default">Progres</h4>
                <v-text-field
                  placeholder="Tuliskan progres yang terukur"
                  v-model="field.progres"
                  color="#0D47A1"
                  outlined
                  dense
                  hide-details
                  class="rounded-lg mb-2"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pa-1">
                <h4 class="txt_color_default">Target</h4>
                <v-text-field
                  placeholder="Tuliskan target yang terukur"
                  v-model="field.target"
                  color="#0D47A1"
                  outlined
                  dense
                  hide-details
                  class="rounded-lg mb-2"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="dataSet.detailTask" no-gutters>
              <v-col cols="12" md="12" class="pa-1">
                <h4 class="txt_color_default">Tujuan Tugas</h4>
                <v-text-field
                  placeholder="Silakan isi tujuan tugas di sini"
                  v-model="dataSet.detailTask.goal"
                  color="#0D47A1"
                  outlined
                  dense
                  hide-details
                  class="rounded-lg mb-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pa-1">
                <h4 class="txt_color_default">Tenggat Waktu</h4>
                <v-menu
                  v-model="menuTenggat"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="dataSet.detailTask.tenggat"
                      prepend-inner-icon="mdi-calendar-range"
                      readonly
                      placeholder="Silakan isi tenggat waktu di sini"
                      hide-details
                      dense
                      v-on="on"
                      outlined
                      class="rounded-lg mb-2"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dataSet.detailTask.tenggat"
                    @input="closeDateMenu(dataSet.detailTask.tenggat)"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" class="pa-1">
                <h4 class="txt_color_default">Metric</h4>
                <v-select
                  placeholder="Silakan pilih metric di sini"
                  outlined
                  hide-details
                  :items="optMetric"
                  item-text="text"
                  item-value="val"
                  dense
                  v-model="dataSet.detailTask.metric"
                  class="rounded-lg mb-2"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" class="pa-1">
                <h4 class="txt_color_default">Progres</h4>
                <v-text-field
                  placeholder="Silakan isi tujuan tugas di sini"
                  v-model="dataSet.detailTask.progres"
                  color="#0D47A1"
                  outlined
                  dense
                  hide-details
                  class="rounded-lg mb-2"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pa-1">
                <h4 class="txt_color_default">Target</h4>
                <v-text-field
                  placeholder="Silakan isi tujuan tugas di sini"
                  v-model="dataSet.detailTask.target"
                  color="#0D47A1"
                  outlined
                  dense
                  hide-details
                  class="rounded-lg mb-2"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
            <div class="d-flex align-center justify-end mt-4 px-1 pb-2">
              <v-btn
                depressed
                class="text-capitalize bg_default"
                @click="simpanData"
                dark
              >
                <h4 v-if="!loadingSave">Simpan</h4>
                <h4 v-if="loadingSave">Loading</h4>
              </v-btn>
            </div>
          </div>
          <div v-if="loading">
            <div v-for="(item, n) in 3" :key="n">
              <v-skeleton-loader
                class="rounded-lg"
                width="90px"
                height="20px"
                type="image"
              ></v-skeleton-loader>
              <v-skeleton-loader
                class="rounded-lg mt-1 mb-2"
                width="100%"
                height="40px"
                type="image"
              ></v-skeleton-loader>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bullet from "../../components/Etc/bullet.vue";
import navbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  components: { navbarProfile, Bullet },
  data() {
    return {
      loading: true,
      loadingSave: false,
      dataSet: null,
      menuTenggat: false,
      field: {
        goal: "",
        tenggat: "",
        progres: "",
        target: "",
        metric: "",
      },
      optMetric: [
        { text: "Persen", val: "%" },
        { text: "Angka", val: "angka" },
      ],
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "List Manajemen Tugas",
          disabled: false,
          href: "/admin/mk/task-management",
        },
        {
          text: "Rincian Tugas",
          disabled: true,
          href: "#",
        },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/task-management?filter[id]=${this.$route.params.idTask}`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          this.dataSet = data.data[0];
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    toFile(n) {
      window.open(n, "blank");
    },
    closeDateMenu(item) {
      this.menuTenggat = false;
      if (!this.dataSet.detailTask) {
        this.field.tenggat == ""
          ? this.$date(item).format("dddd, MMMM Do YYYY")
          : "";
      } else {
        this.dataSet.detailTask.tenggat == ""
          ? this.$date(item).format("dddd, MMMM Do YYYY")
          : "";
      }
    },
    simpanData() {
      this.loading = true;
      let body = {
        task_id: this.$route.params.idTask,
        goal: this.field.goal,
        tenggat: this.field.tenggat,
        progres: this.field.progres,
        metric: this.field.metric,
        target: this.field.target,
      };
      let path = `sysadmin/task-management/task-detail`;

      if (this.dataSet.detailTask) {
        body = {
          id: this.dataSet.detailTask.id,
          task_id: this.$route.params.idTask,
          goal: this.dataSet.detailTask.goal,
          tenggat: this.dataSet.detailTask.tenggat,
          progres: this.dataSet.detailTask.progres,
          metric: this.dataSet.detailTask.metric,
          target: this.dataSet.detailTask.target,
        };
        path = `sysadmin/task-management/task-detail/update`;
      }
      let data = {
        body: body,
        path: path,
      };
      this.$store
        .dispatch("client/postApi", data)
        .then((data) => {
          this.loading = false;
          if (data.status == "success") {
            for (let i in this.field) {
              this.field[i] = "";
            }
            this.$store.dispatch("notify", {
              msg: data.message,
              type: "success",
            });
            this.fetchData();
          } else {
            var key = Object.keys(data.data.errors);
            var values = Object.values(data.data.errors);
            if (key.length == 0) {
              this.$store.dispatch("notify", {
                msg: data.message,
                type: "error",
              });
            }
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                this.$toast.error(values[i][j]);
              }
            }
          }
        })
        .catch((e) => {
          this.loading = false;
          var key = Object.keys(e.data.errors);
          var values = Object.values(e.data.errors);
          if (key.length == 0) {
            this.$store.dispatch("notify", {
              msg: e.message,
              type: "error",
            });
          }
          for (let i = 0; i < key.length; i++) {
            for (let j = 0; j < values[i].length; j++) {
              this.$toast.error(values[i][j]);
            }
          }
        });
    },
  },
};
</script>

<style></style>
